.transformer-freeze {
    position: absolute;
    left: auto !important;
}

.transformer-notouch {
    &.transformer-scroll-over {
        position: fixed;
        transform: translate3d(0,0,0) !important;
        z-index: 31;

        &--top {
            top: 0;
        }

        &--bottom {
            bottom: 0;
        }
    }
}

.transformer-touch {
    &.transformer-scroll-over {
        /*position: absolute;*/
        position: fixed;
        left: auto !important;
        z-index: 31;

        &--top {
            top: 0;
        }

        &--bottom {
            bottom: 0;
        }
    }
}

.transformer-hidden{
    /*display: none !important;*/
    height: 0  !important;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 !important;
    margin: 0 !important;
}

.transformer-sticky {
    position: sticky;
}